
/**
 * Überschreiben der _variables aus Bootstrap bzw. globale Konfiguration von Farben, Abständen etc.
 */

$primary: #41C7A8;
$black: #000;
$white: #fff;
$grey: #A0A0A0;
$font-color1: #263238;
$font-color2: #454545;
$font-main-color: #363636;
$element-border-color: #ededed;
$col-background: #263238;
$slider-button-color: #454545;
$box-border-color: #DDDDDD;
$filter-color: #263238;
$filter-item-border: #707070;
$footer-bg: #263238;

:root {
    --element-margin-bottom: 50px;
    --element-margin-bottom-mobile: 30px;
}

.element-margin-bottom {
    margin-bottom: var(--element-margin-bottom);

    @media (max-width: 991px) {
        margin-bottom: var(--element-margin-bottom-mobile);
    }
}

@mixin element-to-element-margin-small {
    margin-bottom: rem(30px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(50px);
    }
}

@mixin element-to-element-margin-medium {
    margin-bottom: rem(40px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(70px);
    }
}

@mixin element-to-element-margin-large {
    margin-bottom: rem(50px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(90px);
    }
}

@mixin element-to-element-margin-special {
    margin-bottom: rem(130px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(180px);
    }
}

@mixin col-side-paddings {
    padding-left: rem(12px);
    padding-right: rem(12px);
}

@mixin full-bg-element-mobile-margin {
    margin: 0 0.75rem;
}

@mixin col-mobile-bottom-paddings {
    padding-bottom: rem(24px);

    @include media-breakpoint-up(md) {
        padding-bottom: unset;
    }
}

/**
 * Fonts (werden am Ende gelöscht und ersetzt?)
 */

@import "typography";

$color-placeholder: #808080;
$color-grey-muted: #a0a0a0;
$color-grey-dark: #263238;
$color-grey-dark-2: #30383c;
$color-grey-dark-3: #3c4347;
$color-grey-dark-4: #495055;
$color-green: #41C7A8;

$custom-colors: (
    placeholder: $color-placeholder,
    grey-muted: $color-grey-muted,
    grey-dark: $color-grey-dark,
    grey-dark-2: $color-grey-dark-2,
    grey-dark-3: $color-grey-dark-3,
    grey-dark-4: $color-grey-dark-4,
    "green": $color-green
);

:root {
    --t-hero-top-space: 250px; // Space for navbar

    @each $color, $value in $custom-colors {
        --color-#{$color}: #{$value};
    }
}
