@import "../_vendor/font-roboto";

html * {
    font-family: 'Roboto', sans-serif;
    color: $font-main-color;
}

@mixin h3 {
    font-family: 'Roboto';
    font-size: rem(18px);

    @include media-breakpoint-up(md) {
        font-size: rem(24px);
    }
}

@mixin p {
    font-family: 'Roboto';
    font-size: rem(14px);

    @include media-breakpoint-up(md) {
        font-size: rem(18px);
    }
}



