@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.leuffen.de/global/fonts/garamond/font/AGaramondPro-Regular.woff2");
}
@font-face {
    font-family: 'Garamond';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.leuffen.de/global/fonts/garamond/font/AGaramondPro-Italic.woff2");
}
@font-face {
    font-family: 'Garamond';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.leuffen.de/global/fonts/garamond/font/AGaramondPro-Bold.woff2");
}


@font-face {
    font-family: 'Garamond';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.leuffen.de/global/fonts/garamond/font/AGaramondPro-BoldItalic.woff2");
}
